<script>
  import { onMount, onDestroy } from "svelte";
  import { getLanguageData } from "~/core/lang.js";
  import { tweened } from "svelte/motion";
  import { Spinner } from "co";
  import { cubicOut } from "svelte/easing";
  import { fly } from "svelte/transition";

  import { ringtone } from "~/assets/js/ringtone.js";
  import Messages from "./Messages.svelte";
  import unionBy from "lodash/unionBy";
  import orderBy from "lodash/orderBy";
  import FileUpload from "./FileUpload";
  import {
    step,
    operator,
    messages,
    stepNames,
    type,
    user,
    inputs,
    conversation,
    chatLogged,
    auth_token,
    dialog,
    operatorInfo,
    messagesIsRead,
    chatIsOnline$,
    chatActive$,
    projectType$,

    thematic

  } from "~/core/stores";
  import { mutate, subscription, get } from "~/core/api.js";
  import { createMessageModel } from "~/core/utils";
  import { types } from "./helper.js";
  let conversationSub$;
  let messageSub$;
  let isOnlineSub$;

  onDestroy(() => {
    if (conversationSub$) {
      conversationSub$.unsubscribe();
    }
    if (messageSub$) {
      messageSub$.unsubscribe();
    }
    if (isOnlineSub$) {
      isOnlineSub$.unsubscribe();
    }
  });

  let uplodedFile = [];
  let div;

  let fileUploadContentError;
  let messageBody = "";
  let userId, created_at;
  let type_message = false;
  let messageId;
  let type_conversation = true;
  let messageList = [];
  let is_loading = true;
  let totalCount = 0;
  let offset = 0;
  userId = $auth_token.userId;
  let conv_id = $conversation.id;

  $: if (uplodedFile.length > 0 || uplodedFile == 413 || uplodedFile == 415) {
    sendMessage(null, "file");
  }


  function getMessages(_offset = 0) {
    offset += _offset;

    if (offset <= totalCount)
      get(
        "MESSAGES",
        {
          id: $dialog,
          offset
        },
        false
      ).then(req => {
        messageList = [...req.data.messages.reverse(), ...messageList];
        messageList = orderBy(messageList, "id", "asc");
        totalCount = req.data.aggeration.aggregate.count;
        is_loading = false;
        if (offset + 30 >= totalCount) {
          offset = totalCount;
        }
      });
  }
  $: if ($chatIsOnline$ && messageList.length == 0) {
    getMessages();
  }

  function handleNewConversation() {
    conversationSub$ = subscription("GET_CONVERSATIONS_TYPE", {
      id: $conversation.id,
      project_type: $projectType$
    }).subscribe(async response => {
      const resp = await response;
      const conversation = resp.data.online_chat_test_mc_conversations[0];
      if (conversation) {
        switch (conversation.type) {
          case types.ARCHIVE:
            if(!conversation.rating && !messageList.find(x=>x.type == 'rate'))
             messageList = [...messageList,{body : "", created_at : null ,file : null, id : null, type : "rate", user_id : null}]
            else
             messageList = messageList.filter(x=>x.type !== 'rate');
            type_conversation = false;
            break;
          case types.BUSY:
            const infoOperator = conversation.users[0].user;
            if (infoOperator) {
              operatorInfo.set(infoOperator);
            }
            break;
          default:
            break;
        }
        scroll();
      }
    });
    messageSub$ = subscription("NEW_MESSAGE", {
      id: $conversation.id
    }).subscribe(async req => {
      const res = await req;
      if (res.data.messages.length > 0) {
        if (res.data.messages[0].user_id !== userId) {
          messagesIsRead.set(false);
          var audio = new Audio(ringtone);
          audio.play();
        }
        messageList = orderBy(
          unionBy(res.data.messages, messageList, "id"),
          "id",
          "asc"
        );
        scroll();
      }
    });
  }

  handleNewConversation();

  async function sendMessage(e, text) {
    async function messageSender() {
      if(uplodedFile === 413){
        setTimeout(()=>{
          fileUploadContentError = '';
        }, 3000)
        fileUploadContentError = 'Размер файла слишком большой.'
      }else if(uplodedFile === 415){
        setTimeout(()=>{
          fileUploadContentError = '';
        }, 3000)
        fileUploadContentError = 'Тип файла не поддерживается'
      } else{
        try {
        await mutate("ONLINE_CHAT_MC_MESSAGES_INSERT", {
          ...(uplodedFile.length > 0 ? { file_id:  uplodedFile  } : {}),
          body: text ? text : messageBody,
          conv_id: $conversation.id,
          user_id: userId,
          created_at: created_at
        }).then(res => {
          deleteTyping();
          uplodedFile = [];
          if (res.data) {
            messageId =
              res.data.insert_online_chat_test_mc_messages.returning[0].id;
          }else{
            setTimeout(()=>{
              fileUploadContentError = '';
            }, 3000)
            fileUploadContentError = 'Размер вашего файла слишком велик или тип файла не поддерживается'
          }
        });
        messageBody = "";
      } catch (error) {
        console.error(error, 'error fileupload');
      }
      }
      
    }
    // e.preventDefault();
    if (messageBody.trim().length > 0 || text.length > 0) {
      if (!type_conversation) {
        await createConversation();
        await messageSender();
      } else {
        await messageSender();
      }
    }
  }
  let sendedMessage = "";
  async function typing(type = "ONLINE_CHAT_TYPING_INSERT") {
    if (sendedMessage && sendedMessage == messageBody.trim()) {
      return;
    } else {
      sendedMessage = messageBody.trim();
    }
    if (messageBody.trim().length > 0 && $conversation.id != undefined) {
      try {
        await mutate(
          type_message
            ? "ONLINE_CHAT_TYPING_UPDATE"
            : "ONLINE_CHAT_TYPING_INSERT",
          {
            body: messageBody,
            id: $conversation.id
          }
        ).then(res => {
          type_message = true;
          if (res.data) {
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function deleteTyping() {
    if ($conversation.id != undefined) {
      try {
        await mutate("ONLINE_CHAT_TYPING_DELETE", {
          id: $conversation.id
        }).then(res => {
          if (res.data) {
          }
        });
        type_message = false;
      } catch (error) {
        console.error(error);
      }
    }
  }
  async function createConversation() {
    try {
      messageList = messageList.filter(x=>x.type != 'rate');
      const result = await get("CREATE_OR_GET_CONVERATION", {
        dialog_id: $dialog,
        conversation_type: $thematic
      });
      conversation.set(
        result.data.online_chat_test_create_or_get_conversation[0]
      );
      type_conversation = true;
      setTimeout(() => handleNewConversation(), 100);
    } catch (error) {
      console.log(error);
    }
  }

  function handleKeyup(event) {
    if (!event.data && event.keyCode == 13) {
      sendMessage(event);
      event.preventDefault();
      return true;
    } else if (event.keyCode == 32) {
      typing();
      return false;
    }
  }

  async function handleRate(event){
    await mutate("RATE_CONVERSATION", {
          conversation_id: $conversation.id,
          raiting: event.detail,
        })

  }
  function scroll() {
    setTimeout(() => {
      if (div) {
        progress.set(div.scrollHeight);
      }
    }, 0);
    return "";
  }
  const progress = tweened(0, {
    duration: 2000,
    easing: cubicOut
  });
  $: if (div) div.scrollTo(0, $progress);

</script>

<style>
  .margin {
    margin-left: 49px;
  }
  .message-right a {
    color: #fff;
  }
  .chat-messages {
    height: inherit;
    padding: 20px 14px 5px 14px;
    overflow-y: scroll;
    scrollbar-color: #dadada #fff;
    scrollbar-width: thin;
  }
  .chat-messages::-webkit-scrollbar {
    width: 5px;
  }
  .chat-messages::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #dadada;
  }
  .operator_ava {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .conversation {
    width: 100%;
    border-top: 1px solid #d8dee3;
    background-color: white;
  }
  .conversation-input {
    outline: none;
    border: none;
    width: 100%;
    font-size: 16px;
    resize: none;
    font-family: Arial;
    height: 80px;
    overflow-y: scroll;
  }
  .box {
    padding: 7px 15px;
    display: flex;
    justify-content: space-between;
  }
  .text-box {
    width: 266px;
    display: flex;
  }
  textarea {
    scrollbar-color: #fff #fff;
    scrollbar-width: thin;
  }
  textarea::placeholder {
    color: #9fabb7;
    font-size: 15px;
    line-height: 17px;
    font-family: Arial;
  }
  textarea::-webkit-scrollbar {
    width: 3px;
  }
  .send-message {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 40px;
    height: 37px;
  }
  .ava_block {
    margin: 0px 7px 7px 0px;
    align-self: flex-end;
    opacity: 0;
  }
  .display-ava {
    opacity: 1;
  }
  .timemargin {
    margin-bottom: 10px;
  }
  .chat--floating-alert{
    /* display: none; */
    color: white;
    background: #fd9191;
    display: inline-block;
    width: fit-content;
    padding: 10px 15px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  @media only screen and (max-width: 600px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 1200px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 900px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 850px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 800px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 750px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 700px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 650px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 600px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 550px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 500px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 450px) {
  }
  @media only screen and (max-width: 600px) and (max-height: 400px) {
  }

  .chat--inputs {
    display: flex;
  }
</style>
{#if fileUploadContentError}
  <div class="chat--floating-alert">{fileUploadContentError}</div>
{/if}
<div
  class="chat-messages"
  bind:this={div}
  on:scroll={e => (e.target.scrollTop === 0 ? getMessages(30) : '')}>
  {#if is_loading}
    <Spinner />
  {:else}
    {#each messageList as item,index }
      <Messages
        on:rate={handleRate}
        isLast={messageList[messageList.length - 1].id === item.id}
        {...item}
        hasMessage={item.user_id === userId ? 'right' : 'left'} />
      {scroll()}
    {/each}
  {/if}
</div>
<div class="conversation">
  <div class="box">
    <div class="text-box">
      
      <textarea
        bind:value={messageBody}
        type="text"
        class="conversation-input"
        placeholder={getLanguageData().text}
        on:keyup={handleKeyup} />
    </div>
    <div class="chat--inputs">
      <FileUpload bind:data={uplodedFile} />
      <button class="send-message" on:click|preventDefault={sendMessage}>
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="17" fill="#2F80ED" />
          <path
            d="M17.0001 24.5V10M17.0001 10L22.0002 15M17.0001 10L12 15"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
    </div>

  </div>

</div>
