import { get } from './api';

export function checkToken(callback) {
    get('AUTH').then(async response => {
        const user = await response
        if (!user.data.auth_me) {
            localStorage.clear()
            callback(false)
        }
        callback(true)
    }).catch(err => {
        localStorage.clear()
        callback(false)
    })
}

export function formatDate(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes;
    // let strTime = hours + ':' + minutes + ':' + hours;
    // return date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate() + " " + strTime;
}
export function createMessageModel(newMessage, conversation_id, user) {
    return {
        body: newMessage,
        conversation_id: conversation_id,
        created_at_title: formatDate(new Date()),
        user_id: user.id,
        sender: {
            name: user.name
        }
    };
}
export function beautify(num) {
    if (!num || num.length < 12)
        return '';
    let phone = "+"
    phone = [...phone, ...num[0], ...num[1], num[2], " ", ...num[3], ...num[4], " ", ...num[5], ...num[6], ...num[7], " ", ...num[8], ...num[9], " ", ...num[10], ...num[11]]
    num = '';
    for (let i = 0; i < phone.length; i++) {
        num = num + phone[i]

    }
    return num;
}

export function getQueryParams(key = 'conversation_id') {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    return params.get(key)
}