<script>
  import { getLanguageData } from "~/core/lang.js";
</script>

<style>
  .chat--offline {
    justify-self: center;
    align-self: center;
    margin: 30vh 0 0 0;
  }
</style>

<div class="chat--offline">{getLanguageData().offline}</div>
