import Auth from './Auth.svelte';
import Chat from './Chat.svelte';
import Provider  from './Provider.svelte';
import Messages from './Messages.svelte';

export {
    Auth,
    Messages,
    Chat,
}

Chat.Auth = Auth;
Chat.Messages = Messages;   
Chat.Provider = Provider;   
export default Chat