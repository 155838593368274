<script>
  import {
    step,
    stepNames,
    chatIsOnline$,
    auth_token,
    dialog, 
    projectType$
  } from "~/core/stores";
  import { checkToken } from "~/core/utils.js";
  import { subscription } from "~/core/api.js";
  import { onDestroy } from "svelte";
  import { Chat, Spinner, Offline } from "co";
  let isOnlineSub$;
  onDestroy(() => {
    if (isOnlineSub$) {
      isOnlineSub$.unsubscribe();
    }
  });
  isOnlineSub$ = subscription("CHAT_IS_ONLINE", {project_type: $projectType$}).subscribe(async res => {
    const respone = await res;
    let is_online;
    if(res.data.online_chat_test_chat_is_online.length > 0){
       is_online = JSON.parse(
        res.data.online_chat_test_chat_is_online[0].status
      );
    }else{
       is_online = false;
    }
    chatIsOnline$.set(is_online);
    if (is_online && ($auth_token.accessToken && $dialog)) {
      checkToken(is_auth => {
        is_auth ? step.set("chat") : step.set("auth");
      });
    } else if (is_online) step.set("auth");
    else {
      step.set("chat-offline");
    }
  });
  if (!$chatIsOnline$) {
    step.set("chat-loading");
  }
</script>

{#if $step == 'auth'}
  <Chat.Auth />
{:else if $step == 'chat'}
  <Chat />
{:else if $step == 'chat-offline'}
  <Offline />
{:else if $step == 'chat-loading'}
  <Spinner />
{/if}
