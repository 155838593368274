<script>
  import {
    step,
    stepNames,
    user,
    inputs,
    userTerms,
    authExpire,
    conversation,
    auth_token,
    dialog,
    chatIsOnline$,

    thematic

  } from "~/core/stores";
  import { getLanguageData } from "~/core/lang.js";
  import Introduction from "~/UI/InfoTop.svelte";
  import StartChat from "~/UI/Buttons/BlueButton.svelte";
  import Callback from "~/UI/LinkAccess.svelte";
  import { onMount } from "svelte";
  import Spinner from "co/Spinner.svelte";
  import { mutate, subscription } from "~/core/api";
  import { registration, authentification } from "~/assets/js/auth.js";
  import { projectType$ } from "~/core/stores"

  let selectService = "default";
  let isOnlineSub$;
  let user_Id;

  export let authData = {
    name: "",
    phone: "998",
    service: $inputs.service,
    service_type: $inputs.service_type
  };

  let error = {
    name: "",
    phone: "",
    service: ""
  };

  let loading = false;

  let checkAuthdata = event => {
    if($projectType$ !== 'onlineChat'){
      authData.service = "Облачные сервисы"
    }
    if (authData.name == "") {
      error.name = "Введите Ф.И.О";
      setTimeout(() => (error.name = ""), 5000);
      return false;
    }
    if (authData.service == "" || authData.service == "default") {
      error.service = "Выберите услугу";
      setTimeout(() => (error.service = ""), 5000);
      return false;
    }
    return true;
  };

  function authentificationChat(data) {
    try {
      step.set("chat-loading");
      registration().then(c => {
        authentification(resp => {
          const user_id = resp.userId;
          mutate("OPEN_NEW_CONVERSATION", {
            user_id,
            userName: authData.name,
            conversation_type:$thematic
          }).then(rq => {
            dialog.set(rq.data.dialogs.returning[0].id);
            conversation.set(rq.data.dialogs.returning[0].mc_conversations[0]);
            const conv_id = rq.data.dialogs.returning[0].mc_conversations[0].id;
            mutate("INSERT_MESSAGE", {
              userId: user_id,
              conv_id: conv_id,
              message: authData.service
            }).then(rq => {
              setTimeout(() => {
                step.set(stepNames.chat);
              }, 120);
            });
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  function click(event) {
    if (!checkAuthdata(event)) return "";
    inputs.set(authData);
    switch (authData.service) {
      case getLanguageData().auth.service.b:
      thematic.set(1)
        break;
      case getLanguageData().auth.service.c:
      thematic.set(2)
        break;
      case getLanguageData().auth.service.d:
      thematic.set(3)
        break;
    }
    loading = true;
    authentificationChat({
      name: $inputs.name,
      subject_id: $inputs.service
    });
  }
  function callbackHandler(event) {
    // if (!checkAuthdata(event)) return "";
    inputs.set(authData);
    step.set(stepNames.callbackauth);
  }

  function handleKeyup(event) {
    if (event.keyCode == 13) {
      click(event);
      event.preventDefault();
    }
  }

  if ($inputs.name) {
    authData.name = $inputs.name;
  }
</script>

<style>
  .authentication-container {
    width: 100%;
    margin: auto;
    margin-bottom: 0;
    padding: 0px 42px;
    margin-top: 46px;
    box-sizing: border-box;
  }
  .input-authentication {
    width: 100%;
    /* height: 28px; */
    align-items: left;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #ccc;
    color: #445669;
    font-size: 15px;
    -webkit-appearance: none;
    background: transparent;
  }
  .input-authentication::placeholder {
    color: #445669;
    opacity: 1; /* Firefox */
  }

  .input-authentication:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #445669;
  }

  .input-authentication::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #445669;
  }
  .second-input {
    margin-top: 24px;
  }
  .third-input {
    margin-top: 24px;
  }
  .down-arrow {
    position: relative;
    width: 100%;
  }
  .down-arrow:after {
    content: "";
    right: 8px;
    bottom: 2px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
  }
  .down-arrow:before {
    /* background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyODQuOTI5IiBoZWlnaHQ9IjI4NC45MjkiPjxwYXRoIGQ9Ik0yODIuMDgyIDc2LjUxMWwtMTQuMjc0LTE0LjI3M2MtMS45MDItMS45MDYtNC4wOTMtMi44NTYtNi41Ny0yLjg1Ni0yLjQ3MSAwLTQuNjYxLjk1LTYuNTYzIDIuODU2TDE0Mi40NjYgMTc0LjQ0MSAzMC4yNjIgNjIuMjQxYy0xLjkwMy0xLjkwNi00LjA5My0yLjg1Ni02LjU2Ny0yLjg1Ni0yLjQ3NSAwLTQuNjY1Ljk1LTYuNTY3IDIuODU2TDIuODU2IDc2LjUxNUMuOTUgNzguNDE3IDAgODAuNjA3IDAgODMuMDgyYzAgMi40NzMuOTUzIDQuNjYzIDIuODU2IDYuNTY1bDEzMy4wNDMgMTMzLjA0NmMxLjkwMiAxLjkwMyA0LjA5MyAyLjg1NCA2LjU2NyAyLjg1NHM0LjY2MS0uOTUxIDYuNTYyLTIuODU0TDI4Mi4wODIgODkuNjQ3YzEuOTAyLTEuOTAzIDIuODQ3LTQuMDkzIDIuODQ3LTYuNTY1IDAtMi40NzUtLjk0NS00LjY2NS0yLjg0Ny02LjU3MXoiLz48L3N2Zz4="); */
    background-color: transparent;
    background-size: 15px;
    border: none;
    content: "";
    right: 0px;
    top: 0px;
    width: 23px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    pointer-events: none;
    display: block;
  }
  input::placeholder {
    color: #445669;
    font-size: 15px;
    line-height: 17px;
  }
  .errorClass .input-authentication {
    border-bottom: 1px solid red;
    color: red;
  }
  .errorClass .input-authentication::placeholder {
    color: red;
  }
  .errorSelectClass select {
    border-bottom: 1px solid red;
    color: red;
  }
  .chat--offline {
    justify-self: center;
    align-self: center;
    margin: 30vh 0 0 0;
  }
</style>

<Introduction infotop={getLanguageData().auth.introduce} />
<!-- {#if !loading} -->
<div class="d_none authentication-container">
  {#if $projectType$ == 'onlineChat'}
  <div
  class="container-select-service"
  class:errorSelectClass={error.service != ''}>
  <label class="down-arrow">
    <select
      bind:value={authData.service}
      on:keyup={handleKeyup}
      class="input-authentication">
      <option value="default" selected disabled>
        {getLanguageData().auth.service.a}
      </option>
      <option value={getLanguageData().auth.service.b}>
        {getLanguageData().auth.service.b}
      </option>
      <option value={getLanguageData().auth.service.c}>
        {getLanguageData().auth.service.c}
      </option>
      <option value={getLanguageData().auth.service.d}>
        {getLanguageData().auth.service.d}
      </option>
      
    </select>
  </label>
</div>
  {/if}
  

  <div class="input-name second-input" class:errorClass={error.name != ''}>
    <input
      bind:value={authData.name}
      on:keyup={handleKeyup}
      type="text"
      class="input-authentication"
      placeholder={getLanguageData().auth.name} />
  </div>
</div>
<!-- {:else} -->
<!-- <Spinner /> -->
<!-- {/if} -->
<StartChat on:click={click} btntext={getLanguageData().auth.send} />
<!-- <Callback
  on:click={callbackHandler}
  bottomtext={getLanguageData().auth.callbackreq} /> -->
