export function linkify(inputText, color) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;
    const linkTemplate = (the_type = '',symbol="1") => '<a style="color:'+color+';" href="'+the_type+'$'+symbol+'" target="_blank">$'+symbol+'</a>'

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, linkTemplate());


    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, linkTemplate('mailto:'));

    if (replacedText.indexOf('<') === -1) {
        replacePattern2 = /[-a-zA-Z0-9@:%_\+.~#?&\/\/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)?/gim;
        replacedText = replacedText.replace(replacePattern2, linkTemplate('http://','&'));
    }

    return replacedText;
}


export const types = {
    ARCHIVE: 2, EMPTY: 1, BUSY: 3,
}