import { currentLang$ } from "./stores.js";
import { get } from "svelte/store";
import { getQueryParams } from "./utils.js";
export function getLanguageData() {
  let lang =  get(currentLang$)
  if (lang == 'uz') {
    return uz();
  }
  return ru();

}

export function uz() {
  return {
    error: {
      title: "Xato ro'y berdi!",
      body: "Xatolik haqida ma'lumot yuborildi"
    },
    offline: 'Xozirgi vaqtda operatorlar tarmoqda emas',
    welcome: {
      head: "Xabar jo'natish",
      head_2: "Operator bilan onlayn muloqot",
      title: "Bizning operatorlar onlayn!",
      head_inside: "Operator",
      title_inside: "Texnik yordam"
    },
    rate:["A'lo","Yaxshi","Ok","Yomon","Juda yomon"],
    rate_title:"Siz bilan suhbat qanday o'tdi?",
    text: "Xabaringizni yozing",
    baho: "Operator ishini baholang",
    auth: {
      service: {
        a: "Xizmatni tanlang",
        b: "Mobil aloqa",
        c: "Internet",
        d: "Uy telefoni",
        // e: "IPTV",
        // f: "Cloud xizmati",
      },
      introduce: "Iltimos o'zingizni tanishtiring!",
      name: "Ismingiz",
      send: "Yuborish",
      phone: "Telefon Raqam",
      code: "Tasdiqlash kodini yozing!",
      verify: "Tasdiqlash",
      callbackreq: "Qayta aloqaga buyurtma berish",
      reloadsend: 'Qaytadan SMS xabarini jo\'natish'
    },
    call: {
      requestcall: "Qo'ng'iroq qilish",
      cancel: "Bekor qilish",
      numberinfo: "Operator ushbu raqamga qo'ng'iroq qiladi",
      callbackservice: "Qayta qo'ng'iroq qilish xizmati sinov muddati davomida bepul.",
      callbackgoal: " Xizmat sifatini yaxshilash uchun operator bilan suhbatingiz yozib olinadi.",
      acceptservices: "Men shartlarni qabul qilaman",
      acceptservices2: "foydalanuvchi shartnomasi",
      waitforcall: "Operatorni qo'ng'riog'ini  kuting",
      waitinginfo: "Operator 20 minut ichida qo'ng'iroq qiladi",
      cancelcallback: "Qo'ng'iroqni bekoq qilish",
      chatwithoperator: "Operator bilan chatni boshlash",
      backtochat: "Chatga qaytish",
      error: "Xozirgi vaqtda operatorlar tizimda emas, iltimos, keyinroq urinib ko`ring!"
    },


  }
}

export function ru() {
  return {
    error: {
      title: "Произошла ошибка!",
      body: "Данные об ошибке отправлены администраторам"
    },
    offline: "Текущий момент операторы не в сети",
    welcome: {
      head: "Напишите ваше сообщение",
      head_2: "Онлайн чат с оператором",
      title: "Операторы онлайн!",
      head_inside: "Оператор",
      title_inside: "Техническая поддержка"
    },
    rate:["Великолепно","Отлично","Ок","Плохо","Ужасно"],
    rate_title:"Как прошел разговор с вами?",
    text: "Введите сообщение и нажмите Enter",
    rate: "Оцените работу Оператора",
    auth: {
      service: {
        a: "Выберите услугу",
        b: "Мобильная связь",
        c: "Интернет",
        d: "Домашний телефон",
        // e: "IPTV",
        // f: "Облачные сервисы",
      },
      introduce: "Представьтесь, пожалуйста",
      name: "Ваше имя",
      send: "Начать чат с оператором",
      phone: "Ваш телефон",
      code: "SMS подтверждение",
      verify: "Подтвердить",
      callbackreq: "Заказать обратный звонок",
      reloadsend: 'Снова отправляю смс'
    },
    call: {
      requestcall: "Заказать звонок",
      cancel: "Отменить",
      numberinfo: "Оператор перезвонит на номер",
      callbackservice: "Услуга \"Обратный звонок\" бесплатна, на время тестового периода.",
      callbackgoal: " В целях улучшения качества обслуживания ваш разговор с оператором будет записан.",
      acceptservices: "Я принимаю условия",
      acceptservices2: "пользовательского соглашения",
      waitforcall: "Ожидайте звонка оператора",
      waitinginfo: "Оператор перезвонит в течении 20 минут",
      cancelcallback: "Отменить звонок",
      chatwithoperator: "Начать чат с оператором",
      backtochat: "Вернуться в чат",
      error: "Текущий момент операторы не в сети, повторите попытку позже!"
    }
  }
}


//   }
// }