<script>
  export let btntext,
    disabled = false;

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  function click() {
    dispatch("click");
  }
</script>

<style>
  .success-btn {
    display: flex;
    background: #2f80ed;
    border-radius: 3px;
    padding: 11px 32px;
    width: 252px;
    color: white;
    font-size: 16px;
    justify-content: center;
    margin: auto;
    margin-top: 47px;
    border: none;
    cursor: pointer;
    outline: none;
  }
  @media only screen and (max-width: 600px) {
    .success-btn {
      margin-top: auto;
    }
  }
</style>

<button {disabled} on:click={click} class="success-btn">{btntext}</button>
