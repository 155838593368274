import { messagesIsRead, conversation, auth_token } from '~/core/stores.js';
import { get } from 'svelte/store';
import { mutate } from '~/core/api.js'

export default function activityWatcher(node) {
  var secondsSinceLastActivity = 0;
  let isRead = true;


  setInterval(function () {
    secondsSinceLastActivity++;
    if (secondsSinceLastActivity > 1) {
      isRead = get(messagesIsRead)
    }
  }, 1000);

  function activity() {
    if (!isRead) {
      isRead = true
      messagesIsRead.set(true)
      const id = get(conversation).id;
      const user_id = get(auth_token).userId;
      mutate('ONLINE_CHAT_MC_NOTIFICATIONS_UPDATE', { id, user_id })
    }
    secondsSinceLastActivity = 0;
  }

  var activityEvents = [
    "mousedown",
    "mousemove",
    "keydown",
    "scroll",
    "touchstart"
  ];

  activityEvents.forEach(function (eventName) {
    node.addEventListener(eventName, activity, true);
  });

}