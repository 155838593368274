<script>
  export let infotop;
</script>

<style>
  .info-top {
    color: #9fabb7;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    cursor: default;
  }
</style>

<p class="info-top">{infotop}</p>
