export const queries = {};
queries.AUTH = `
  {
    auth_me {
      id
    }
  }
  `

 queries.CREATE_OR_GET_CONVERATION = `
 mutation CreateOrGetConversation($conversation_type: Int, $dialog_id: Int!) {
  online_chat_test_create_or_get_conversation(args: {conversation_type_arg: $conversation_type, dialog_id_arg: $dialog_id}) {
    id
    type
  }
}
 `

queries.OPEN_NEW_CONVERSATION = `
mutation INSERT_DIALOG($userName: String, $user_id: uuid!, $conversation_type: Int) {
  update_user(_set: {name: $userName}, where: {id: {_eq: $user_id}}) {
    affected_rows
  }
  dialogs: insert_online_chat_test_mc_dialogs(objects: {mc_conversations: {data: {conversation_type: $conversation_type, mc_conversation_users: {data: {user_id: $user_id}}}}}) {
    returning {
      id
      mc_conversations {
        id
      }
    }
  }
}

`;
queries.INSERT_MESSAGE = `
mutation($userId: uuid!, $conv_id: Int!, $message: String!) {
  insert_online_chat_test_mc_messages(objects: {mc_message_notifications: {data: {user_id: $userId, conversation_id: $conv_id}}, conversation_id: $conv_id, body: $message, user_id: $userId}) {
    returning {
      body
      created_at
      conversation_id
      user {
        role
      }
    }
  }
}`;
queries.REGISTER = `
mutation ($email: String!, $password: String!, $project_type: String) {
  auth_register(email: $email, password: $password, project_type: $project_type)
}
`;

queries.REFRESH = `
mutation {
  auth_refresh_token {
    accessToken
    refreshToken
    userId
  }
}
`
queries.auth_login = `
    mutation($email: String!, $password: String!) {
        auth_login(email: $email, password: $password) {
            accessToken
            refreshToken
            userId
        }
    }
`;
queries.INSERT_ORDER = `
mutation(
  $subject_id: numeric!
  $name: String!
  $phone: Int!
  $operator: uuid!
) {
  insert_callback_orders(
    objects: {
      subject_id: $subject_id
      name: $name
      phone: $phone
      operator_id: $operator
    }
  ) {
    returning {
      status
    }
  }
}
`;
queries.OPERATORS = `
{
  user(where: {_and: {project_type: {_eq: "callback"}, role: {_eq: "operator"}, is_online: {_eq: true}}}) {
    id
    orders_aggregate(where: {created_at: {_gte: "today()"}}) {
      aggregate {
        count
      }
    }
  }
}

`;
queries.ONLINE_CHAT_MC_MESSAGES_INSERT = `
mutation ($file_id: uuid, $body: String!, $conv_id: Int!, $user_id: uuid) {
  insert_online_chat_test_mc_messages(objects: {mc_message_notifications: {data: {user_id: $user_id, conversation_id: $conv_id}}, body: $body, file_id: $file_id, conversation_id: $conv_id, user_id: $user_id}) {
    returning {
      body
      conversation_id
      id
    }
  }
}
`;

queries.ONLINE_CHAT_MC_NOTIFICATIONS_UPDATE = `
mutation ($id: Int!, $user_id: uuid!) {
  update_online_chat_test_mc_message_notification(where: {user_id: {_neq: $user_id}, conversation_id: {_eq: $id}}, _set: {is_seen: true}) {
    returning {
      conversation_id
      is_seen
      id
    }
  }
}
`;

queries.ONLINE_CHAT_TYPING_INSERT = `
mutation ($id: Int!, $body: String!) {
  insert_online_chat_test_mc_typings(on_conflict: {update_columns: [body], constraint: mc_typings_pkey}, objects: {body: $body, id: $id, conversation_id: $id}) {
    returning {
      body
      id
    }
  }
}
`;
queries.ONLINE_CHAT_TYPING_UPDATE = `
mutation ($id: Int!, $body: String!){
  update_online_chat_test_mc_typings(_set: {body: $body}, where: {id: {_eq: $id}, conversation_id: {_eq: $id}}) {
    returning {
      body
      id
    }
  }
}

`;
queries.ONLINE_CHAT_TYPING_DELETE = `
mutation ($id: Int!){
  delete_online_chat_test_mc_typings(where: {conversation_id: {_eq: $id}, id: {_eq: $id}}) {
    returning {
      updated_at
    }
  }
}
`;

queries.MESSAGES = ` 
query getMessages($id: Int, $offset: Int) {
  aggeration: online_chat_test_mc_messages_aggregate(where: {mc_conversation: {dialog_id: {_eq: $id}}}) {
    aggregate {
      count
    }
  }
  messages: online_chat_test_mc_messages(limit: 30, offset: $offset, where: {mc_conversation: {dialog_id: {_eq: $id}}}, order_by: {created_at: desc}) {
    body
    created_at
    id
    user_id
    conversation_id
    type
    file {
      path
      name
      extension
    }
  }
}

`
queries.NEW_MESSAGE = ` 
subscription getMessages($id: Int) {
  messages: online_chat_test_mc_messages_view(where: { conversation_id: {_eq: $id}}) {
    body
    created_at
    id
    type
    user_id
    file {
      path
      name
      extension
    }
  }
}
`;

queries.GET_CONVERSATIONS_TYPE = `
subscription ($id: Int!, $project_type: String!) {
  online_chat_test_mc_conversations(where: {id: {_eq: $id}, mc_conversation_users: {user: {project_type: {_eq: $project_type}}}}) {
    type
    rating
    users: mc_conversation_users(where: {user: {role: {_eq: "operator"}}}) {
      user {
        name
      }
    }
  }
}
`;

queries.CHAT_IS_ONLINE = `
subscription($project_type: String!) {
  online_chat_test_chat_is_online(where: {project_type: {_eq: $project_type}}){
    status
  }
}
`
queries.CALLBACK_IS_ONLINE = `
subscription {
  callback_callback_is_online{
    status
  }
}
`


queries.ADD_PHONE = `
mutation addPhone($id: uuid, $phone: String!) {
  update_user(where: {id: {_eq: $id}}, _set: {phone: $phone}) {
    affected_rows
  }
}


`

queries.RATE_CONVERSATION = `
mutation ReviewConversation($conversation_id: bigint, $raiting: smallint) {
  online_chat_test_review_conversation(args:{conv_id: $conversation_id, review_arg: $raiting} ) {
    id
    rating
  }
}
`
queries.GET_CONFIGS = `
query GetConfigs {
  online_chat_test_configs(where: {key: {_eq: "telegram_bot_url"}}) {
    key
    value
  }
}
`
