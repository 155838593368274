<label>
  
  <svg   class="input--svg--icon" xmlns="http://www.w3.org/2000/svg"  viewBox="-144 0 514 514.4389" ><g><path d="m97.929688 513.484375c-56.71875-7.296875-97.710938-58.335937-97.710938-115.519531v-313.992188c0-41.960937 30.59375-79.496094 72.34375-83.578125 47.90625-4.679687 88.414062 33.019531 88.414062 79.992188v319.621093c0 23.324219-15.722656 44.753907-38.613281 49.191407-30.785156 5.980469-57.839843-17.554688-57.839843-47.296875v-289.363282c0-8.875 7.203124-16.078124 16.074218-16.078124 8.875 0 16.078125 7.203124 16.078125 16.078124v289.363282c0 8.875 7.199219 16.078125 16.074219 16.078125s16.078125-7.203125 16.078125-16.078125v-319.617188c0-23.328125-15.722656-44.757812-38.617187-49.195312-30.785157-5.980469-57.839844 17.554687-57.839844 47.296875v317.933593c0 41.957032 30.59375 79.492188 72.339844 83.578126 47.90625 4.660156 88.417968-33.019532 88.417968-79.996094v-289.363282c0-8.875 7.203125-16.078124 16.078125-16.078124 8.871094 0 16.074219 7.203124 16.074219 16.078124v289.363282c0 66.925781-58.742188 120.394531-127.351562 111.582031zm0 0" fill="#fff" data-original="#fff" class="active-path" data-old_color="#fff"/></g> </svg>
  <input id="input" class="file-upload" size="60" bind:files type="file" {...multiple ? { multiple: true } : {}} 
  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*" />
</label>

<script>
  import { createEventDispatcher } from 'svelte'
    import { auth_token } from '~/core/stores'

  const dispatch = createEventDispatcher()

  
  let files = []
  let selected = 0
  
  export let data = []
  export let update = false
  export let id = null
  export let multiple = false

  $: saveFiles(files)

  function saveFiles() {
    for (var i = 0; i < files.length; i++) {
      if(files[i].size < 10485760){
        //there is type as well
        console.log(Validate())
        if(Validate()){
          let mainFile = files[i]
          let form = new FormData();
          form.append('file',mainFile);
          fetch(`${STORAGE_HOST.replace("/uploads",'')}`,{
            method:"POST",
            headers:{
              "Authorization":`Bearer ${$auth_token.accessToken}`
            },
            body:form
          }).then(res=>res.json()).then(x=>{
            data = x.file_id;
            files = [];
            document.getElementById("input").value = null;
            
            dispatch("uploaded",x.file_id)
          })
          // getBase64(files[i]).then(res => {
          //   let file = {}
          //   file.name = mainFile.name.substr(mainFile.name, mainFile.name.indexOf('.'))
          //   if (update) Object.assign(file, id)
          //   file.path = res
          //   file.extension = '.' + mainFile.name.slice((Math.max(0, mainFile.name.lastIndexOf('.')) || Infinity) + 1)
          //   if((typeof data) === 'number'){
          //     data = [];
          //   }
          //   data = [file, ...data]
          //   // getFiles(data)
          // })
        }else{
          data = 415;
        }
        
      }else{
        data = 413;
      }
      
    }
  }
  var _validFileExtensions = ['.txt', '.jfif', '.m4a', '.PDF', '.heic', '.lnk', '.3gp', '.amr', '.MP4', '.HEIC', '.pptx', '.MOV', '.xml', '.doc', '.docx', '.aac', '.PNG', '.ogg', '.xlsx', '.DOC', '.mp3', '.png', '.3gpp', '.jpg', '.gif', '.xls', '.mov', '.DOCX', '.webp', '.jpeg', '.JPG', '.mp4', '.pdf'];    
  function Validate() {
      var arrInputs = files;
      for (var i = 0; i < arrInputs.length; i++) {
          var oInput = arrInputs[i];
          // if (oInput.type == "file") {
              var sFileName = oInput.name;
              if (sFileName.length > 0) {
                  var blnValid = false;
                  for (var j = 0; j < _validFileExtensions.length; j++) {
                      var sCurExtension = _validFileExtensions[j];
                      if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                          blnValid = true;
                          break;
                      }
                  }
                  
                  if (!blnValid) {
                    console.log('false')
                      // alert("Sorry, " + sFileName + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "));
                      return false;
                  }
              }
          // }
      }
    
      return true;
    }
  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }
  function check(index) {
    for (var i = 0; i < data.length; i++) {
      if (i != index) {
        data[i].is_main = false
      }
    }
  }
</script>

<style>
  :root {
    --l-grey-se: #bec4d1;
  }
  label {
    cursor: pointer;
    width: 28px;
    margin-right: 5px;
  }

  input[type='file'] {
    display: none;
  }
  .element-count {
    position: absolute;
    display: -webkit-box;
    display: flex;
    background-color: #567ff8;
    bottom: 3px;
    right: 0px;
    padding: 5px;
    border: solid 2px var(--white);
    border-radius: 50%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
  }

  span {
    position: relative;
  }
  .input--svg--icon{
      background: #2f80ed;
    color: #fff;
    padding: 5px;
    height: 35px;
    border-radius: 50%;
  }
</style>
