
import { GraphQL } from "graphql-svelte";
import { GraphQLProvider } from "graphql-svelte";
import { auth_token, chatActive$, step, conversation,chatIsOnline$ } from './stores'
import { error as errorHandler } from "co/error/errorHandler";
import { mutate } from "./api";
import { queries } from "~/queries";
import { get } from "svelte/store";
let token;
let errorCount = 0


function getToken() {
  return token;
}

let addintions = {};
function getAddintion() {
  return addintions;
}

let client = GraphQLProvider({

  url: URL_GQL_HOST,
  headers: () => ({
    "content-type": "application/json",
    ...getAddintion(),
    ...getToken() ? { authorization: `Bearer ${getToken().accessToken}` } : '',
  }),
  ws: {

    url: URL_GQL_WS
  }
})

auth_token.subscribe(_token => {
  if (_token) {
    token = _token
    if (typeof client != 'undefined') {
      client.subscription.tryReconnect()
      client.subscription.connectionCallback = async (err) => {
        if (err == "Could not verify JWT: JWTExpired" && token.refreshToken) {
          addintions = { "x-refresh-token": token.refreshToken }
          token = null
          let res = await mutate(queries.REFRESH);
          if (res.data) {
            auth_token.set(res.data.auth_refresh_token);
            token = res.data.auth_refresh_token

            chatActive$.set(false);

            setTimeout(() => {
              chatActive$.set(true);
            }, 150)
          }
        } else if (err == "Could not verify JWT: JWTExpired" && !token.refreshToken) {
          auth_token.set(false);
          chatActive$.set(false);
          conversation.set(null)
          
          setTimeout(() => {
            chatActive$.set(true);
            step.set("auth");
            chatIsOnline$.set(true)  
          }, 500)
        }

      }
    }

  }
})
client.graphql.on('cache', reportCacheErrors)
function reportCacheErrors({
  cacheKey,
  cacheValue: {
    fetchError,
    httpError,
    parseError,
    graphQLErrors
  }
}) {
  if (fetchError || httpError || parseError || graphQLErrors) {
    console.groupCollapsed("GraphQL cache errors for key \u201C".concat(cacheKey, "\u201D:"));
    errorCount++;
    if (errorCount) {
      return;
    }

    if (fetchError) {
      console.groupCollapsed('Fetch:');
      console.log(fetchError);
      errorHandler(JSON.stringify(fetchError), 'Fetch:', location)
      console.groupEnd();
    }

    if (httpError) {
      console.groupCollapsed('HTTP:');
      console.log("Status: ".concat(httpError.status));
      console.log("Text: ".concat(httpError.statusText));
      errorHandler(JSON.stringify(`${httpError.status} ${httpError.statusText}`), 'HTTP:', location)
      console.groupEnd();
    }

    if (parseError) {
      console.groupCollapsed('Parse:');
      console.log(parseError);
      errorHandler(JSON.stringify(parseError), 'Parse:', location)
      console.groupEnd();
    }

    if (graphQLErrors) {
      console.groupCollapsed('GraphQL:');
      errorHandler(JSON.stringify(graphQLErrors), 'GraphQL:', location)
      graphQLErrors.forEach(({
        message
      }) => console.log(message));
      console.groupEnd();
    }
    console.groupEnd();
  }
}

export { client }
export default client