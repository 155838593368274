import { writable } from 'svelte/store';
import { syncable, setPrefix } from 'svelte-syncable';
setPrefix('telecom-widget');
export const user = syncable('user', {});
export const inputs = syncable('inputs', {});
export const thematic = syncable('thematic', 1);
export const callbackExpire = syncable('callbackExpire', '');
export const chatLogged = writable(false);


export const callbackIsExpire = syncable('callbackIsExpire', true);
export const conversation = syncable('conversation', {});
export const dialog = syncable('dialog', false);
export const operatorInfo = writable({});

export const step = writable('auth');
export const operatorsOnline$ = writable(false);
export const chatActive$ = writable(true);
export const chatIsOnline$ = writable(false);
export const userTerms = writable(false);
export const authExpire = syncable('expire_at', 0);
export const stepNames = {
    auth: 'auth',
    order: 'order',
    verification: 'verification',
    chat: 'chat',
    callback: 'callback',
    waiting: 'waiting',
    callinfo: 'callinfo',
    callbackauth: 'callbackauth',
    loading: 'loading',
    offline: 'offline'
}

export const chat_steps = ['auth', 'chat','chat-offline','chat-loading']
export const callback_steps = ['order', 'verification', 'callback', 'waiting', 'callinfo', 'callbackauth','callback-offline','callback-loading']

export const operator = writable('operator', {});
export const type = writable({});


function createMessage() {
    const { subscribe, set, update } = writable([]);

    return {
        subscribe,
        add: (data) => set(data),
        push: (data) => update(n => [...n, data]),
        pop: (data) => update(n => n.filter(c => c.id == data.id)),
        reset: () => set([])
    };
}


export const messages = createMessage();
export const clicked = writable(false);
export const projectType$ = writable();
export const currentLang$ = writable('uz');

export const auth_token = syncable('auth', false);

export const messagesIsRead = writable(true);
export const error = writable(false);
