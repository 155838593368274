
export function error(body, type, path) {
    let data = {
        body,
        ...path ? { path: JSON.stringify(path) } : {},
        ...type ? { type } : { type: path.path.split('/').filter(c => c)[0] },

    }

    fetch(`${SERVER_HOST}/handling`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
    }).then(c => {

    })
}

