<script>
    import { createEventDispatcher, onMount } from 'svelte';
    import { getLanguageData } from '~/core/lang'

    const dispatch = createEventDispatcher();

    export let modelValue = 0;
    export let allowHalf = false;
    export let disabled = false;
    export let allowClear = false;
    export let count = 5;
    export let strokeColor = '#f7ba2a';
    export let backgroundColor = '#c6d1de';
    export let toolTips = [];
    export let icon = 'icon-favorite-filling';

    let mapCountArr = [];

    $: score = Number(modelValue);
    $: touchHalf = modelValue !== Number(modelValue);

    onMount(() => {
        if (count > 0) {
            mapCountArr = [];
            for (let i = 1; i <= count; i++) {
                mapCountArr.push(i);
            }
            mapCountArr
        }
    })

    const getToolTip = (index) => {
        return toolTips[index] || '';
    } 

    const handleMouseEnter = (item, event) => {
        if (disabled) {
            return;
        }

        if (allowHalf) {
            updateTouchHalf(item, event);
            score = touchHalf ? item - 0.5 : item;
            return;
        }

        score = item;
    }

    const handleMouseLeave = () => {
        if (disabled) {
            return;
        }
        if (allowHalf) {
            touchHalf = Number(modelValue) !== Math.floor(Number(modelValue));
        }

        score = Number(modelValue);
    }

    const updateTouchHalf = (item, event) => {
        let target = document.getElementsByClassName(`star${item}`)[0];

        if (target && event.offsetX <= target.clientWidth / 2) {
            touchHalf = true;
            return;
        }

        touchHalf = false;
    }

    const handleClick = () => {
        if (disabled) {
            return;
        }

        let clearValue = false;
        let emitValue = 0;

        if (allowClear) {
            clearValue = Number(modelValue) === score;
        }

        emitValue = clearValue ? 0 : Number(score);

        dispatch('change', emitValue);
    }
    
</script>

<div class="xl-rate-wrap">
    <p class="xl-rate-title">{getLanguageData().rate_title}</p>
    <div class="xl-rate-body">
    {#each mapCountArr as item, index (index)}
        
        <div class="xl-rate-item star{item}"
            style="cursor: {disabled ? 'auto' : 'pointer'}"
            title={getToolTip(index)}
            on:mousemove={(event) => handleMouseEnter(item, event)} 
            on:mouseleave={(event) => handleMouseLeave(item, event)}
            on:keydown={handleClick}
            on:click={handleClick}
             >
            <div class="xl-rate-full">
                <span class="star-fill">
                    <img src="https://api.cdn.uzcloud.uz/public-images/{item}.png" />

                </span>
            </div>
        </div>
    {/each}
    </div>
</div>

<style>
    .xl-rate-wrap{
        display: flex;
        justify-content: center;
        height: 89px;
        box-shadow: 1px 1px 1px 1px #e9e9df;
        border-radius: 6px;
        margin-top: 12px;
        margin-bottom: 12px;
        flex-direction: column;
        border-top: 2px solid #2f80ed;
    }
    .xl-rate-body{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }
    .xl-rate-title{
        text-align: center;
    }
    .iconfont {
        font-size: 32px;
    }
    .star-fill{
        color: yellow;
    }
    img{
        width: 32px;
        /* padding: 0 12px; */
        transition: transform 0.2s;
    }
    img:hover{
        transform: scale(1.5);
    }

    .xl-rate-item {
        display: inline-block;
        position: relative;
    }
    .xl-rate-iconfont-main {
        position: relative;
        font-size: 24px;
        margin-right: 8px;
        vertical-align: baseline;
    }
    .xl-rate-half {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
    }
</style>