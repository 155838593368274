<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { linkify } from "./helper.js";
  import { auth_token } from "~/core/stores";
  import Modal from "~/components/Modal.svelte";
    import Rate from "~/UI/Rate.svelte"
    import { getLanguageData } from "~/core/lang.js"
  
    const dispatch = createEventDispatcher();
  export let type;
  export let hasMessage = "left";
  export let isLast;
  export let created_at;
  export let body;
  let show = false;
  let src;
  let sourceFile;

  function isImage(extension) {
    return [".jpg", ".png"].includes(extension);
  }
  export let file = {
    path: "",
    extension: "",
    name: "",
    alt: ""
  };


  const getBase64Image = async res => {
        const blob = await res.blob();

        const reader = new FileReader();

        await new Promise((resolve, reject) => {
          reader.onload = resolve;
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
        return reader.result;
      };


  const fetchImage = () =>  fetch(`${STORAGE_HOST}${file.path}`,{
      headers:{
        "Authorization":`Bearer ${$auth_token.accessToken}`
      }}).
      then(getBase64Image)
      .then(i=> {
      sourceFile = i
    })

    onMount(()=>{
      if(file) {
        fetchImage().catch(()=>{fetchImage()})
      }
    })

  let userId = auth_token.userId;

  function timeHelper(date) {
    var today = new Date(date);
    var created_at =
      (today.getHours() < 10 ? "0" : "") +
      today.getHours() +
      ":" +
      (today.getMinutes() < 10 ? "0" : "") +
      today.getMinutes();
    return created_at;
  }

  let resultScore = 0;
	let toolTips = getLanguageData().rate.reverse() ;

  let zoomPicture = () => {
    show = true;
    src = `${sourceFile}`;

  }

</script>

<style>
  .message-left {
    background: #f1f0f0;
    border-radius: 12px 12px 12px 12px;
    margin-right: 7px;
  }
  .message-left-last {
    border-radius: 12px 12px 12px 0px;
    margin-bottom: 10px;
  }
  .message {
    min-width: 50px;
    max-width: 300px;
    padding: 12px 16px;
    box-sizing: border-box;
    font-size: 15px;
    margin-top: 2px;
    word-wrap: break-word;
    color: #445669;
    display: inline-block;
  }
  .message-left {
    background: #f1f0f0;
    border-radius: 12px 12px 12px 12px;
    margin-right: 7px;
  }
  .message-left-last {
    border-radius: 12px 12px 12px 0px;
    margin-bottom: 10px;
  }
  .messages {
    display: flex;
  }
  .message-right {
    background: #2f80ed;
    border-radius: 12px 12px 12px 12px;
    color: white;
    align-self: flex-end;
    margin-left: 7px;
  }
  .message-right-last {
    border-radius: 12px 12px 0px 12px;
    margin-bottom: 10px;
  }
  .time {
    margin: 0px;
    font-size: 12px;
    line-height: 14px;
    color: #9fabb7;
    display: inline;
    align-self: center;
  }
  .time.right {
    position: absolute;
    left: 30px;
  }
  .right {
    justify-content: flex-end;
  }
  .right:first-child {
    display: none;
  }
  .message-right a {
    color: #fff;
  }

  .messages a {
    position: relative;
  }
  .message-left.message-file {
    padding: 20px 50px 20px 15px;
  }
  img {
    padding: 5px 0 5px 5px;
    width: 50%;
  }
</style>

<Modal bind:src bind:show />
{#if type == 'rate'}
    <Rate on:change={(e) => dispatch('rate',e.detail)} toolTips={toolTips}  modelValue={resultScore}  ></Rate>
{:else if type != 'deleted'}
  <div
    class="messages {hasMessage}"
    in:fly={isLast ? { x: 200, duration: 150 } : {}}>
    {#if hasMessage === 'right'}
      <p class="time">{timeHelper(created_at)}</p>
    {/if}
    {#if file}
      {#if isImage(file.extension) && sourceFile}
        <img
          on:click={zoomPicture}
          on:keyup={zoomPicture}
          src="{sourceFile}"
          class="image-message"
          alt={body || file.name} />
      {:else}
        <div class="message-{hasMessage} message link message-file">
          <a
            href="{sourceFile}"
            download
            alt={body || file.name}
            class="file">
            {body || file.name}
          </a>
        </div>
      {/if}
    {:else}
      <div class="message-{hasMessage} message link">
        {@html linkify(body)}
      </div>
    {/if}
    {#if hasMessage === 'left'}
      <p class="time">{timeHelper(created_at)}</p>
    {/if}
  </div>
  
{/if}
