<script context="module">
  import { tick, setContext, getContext, onMount } from "svelte";
  import { error as errorHandler } from "./errorHandler";
  
  // const schedulerPromise = tick();
  // const schedulerPromiseThen = schedulerPromise.then;
  // schedulerPromise.then = then;
  // function then(mbFlush, mbReject) {
  //   if (typeof mbReject === "function") return mbFlush();
  //   return schedulerPromiseThen.call(schedulerPromise, mbFlush).catch(ex => {
  //     debugger
  //     document.getElementById('chat-error-container').style="display:block"
      
  //     errorHandler(ex, "widget", location);
  //   });
  // }
</script>

<script>
  onMount(() => {});
  let pending = false;
  let error = null;
  const slots = $$props.$$slots;
  let originalSlot = slots.default[0];
  slots.default[0] = ctx => {
    try {
      return originalSlot(ctx);
    } catch (ex) {
      onCatch(ex);
    }
  };
  setContext("__suspense_catch", onCatch);
  function onCatch(ex) {
    (ex instanceof Promise ? schedulePending : scheduleException)(ex);
  }
  export function refresh() {
    pending = false;
    error = null;
  }
  function schedulePending(promise) {
    promise.finally(_ => (pending = false));
    pending = true;
  }
  function scheduleException(errorArg) {
    error = errorArg;
  }
</script>

{#if error}
  <slot name="error" {error} />
{:else if pending}
  <slot name="pending" />
{:else}
  <slot {refresh} />
{/if}
