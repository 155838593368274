
import {   smsConfirm, smsSend} from './archive-api'

import { queries } from "../queries"
import client from './apollo'

export async function get(query, variables = null) {
  return await client.get({ query: queries[query] || query, variables }, false)
}

export function query(query, variables = null) {
  return client.query({ query: queries[query] || query, variables })
}

export async function mutate(mutation, variables = null) {
  return await client.mutate({ query: queries[mutation] || mutation, variables })
}

export function subscription(query, variables = null) {
  return client.subscribe({ query: queries[query] || query, variables })
}

export function restore(query, data) {
  return client.restore(query, data)
}



export{
   smsConfirm, smsSend
}