import { inputs, auth_token, authExpire, projectType$ } from "../../core/stores.js";
import { mutate } from "../../core/api";
import {get} from 'svelte/store'
let name = '';

const observable = inputs;


let email;
let password;

export function registration() {
    const user_inputs = get(observable)
    name = user_inputs.name
    
    email = `${name + new Date().getTime().toString()}@client.uz`;
    password = "client!@EQWWGE#@WAFSDGWHW$#GQW";
    try {
        return mutate(
            "REGISTER", { email, password, project_type:get(projectType$)  }
        );
    } catch (error) {
        console.error(error)
    }
}

export function authentification(callback) {
    try {
        mutate(
            "auth_login", { email, password }
        ).then(rq => {
            if (rq.data) {
                auth_token.set(rq.data.auth_login)
                callback(rq.data.auth_login)
                const twentyThreeHours = 23 * 60 * 60 * 100;
                let time = new Date().getTime();
                authExpire.set(time + twentyThreeHours);
            }
        });
    } catch (error) {
        console.error(error)
    }
}   