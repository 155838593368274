<script>
  import { error as errorHandler } from "./errorHandler.js";
  import { getLanguageData } from "~/core/lang.js";
  export let error;
  export let refresh = () => "";

  errorHandler(`${error.message} ${error.stack}`, "widget", location);
</script>

<style>
  .chat-error-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
</style>

<div id="chat-error-container" class="chat-error-container">
  <h1>{getLanguageData().error.title}</h1>
  <div>{getLanguageData().error.body}</div>
  <br />
</div>
