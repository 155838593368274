<script>
  export let show = false;
  export let src;

  const handleKeyDown = node => {
    function close(e) {
      if (e.key === "Escape") {
        show = false;
      }
    }
    function closeByClick(e) {
      show = false;
    }
    window.addEventListener("keydown", close);
    return {
      destroy: () => {
        window.removeEventListener("keydown", close);
      }
    };
  };
</script>

<style>
  .chat--modal--cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(1, 1, 1, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }
  .chat--modal--content {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-self: center;
    z-index: 2;
  }
  .chat--modal--close:hover {
    font-size: 34px;
  }
  .chat--modal--close {
    font-size: 36px;
    margin: 0 0 10px 0;
    z-index: 2;
    cursor: pointer;
    align-self: flex-end;
    color: white;
  }
  .chat--modal--image {
    object-fit: contain;
  }
</style>

{#if show}
  <div on:click|self={()=> show=false } use:handleKeyDown class="chat--modal--cover">
    <div class="chat--modal--content">
      <span class="chat--modal--close" on:click={() => (show = false)}>x</span>
      <img class="chat--modal--image" {src} alt="none" />
    </div>

  </div>
{/if}
